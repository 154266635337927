import React, { useCallback } from "react"
import logoText from "../images/logo-text.png"

type Props = {
  color?: "black" | "white"
  height?: number
}

const Logo: React.FC<Props> = ({ color = "black", height = 50 }) => {
  const renderImage = useCallback(() => {
    return (
      <a href="/">
        <img
          src={logoText}
          alt="Players Swim logo"
          height={height}
          style={{
            margin: "0",
            filter: color == "white" ? "invert(100%)" : "",
          }}
        />
      </a>
    )
  }, [color])

  return renderImage()
}
export default Logo
