import * as React from "react"
import styled from "styled-components"

const Container = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  padding: 20px 30px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: 0 50px;
  }
`

const Section = ({ children }) => {
  return <Container>{children}</Container>
}

export default Section
