import React, { useEffect, useState } from "react"
import styled from "styled-components"

import { Link } from "gatsby"
import Logo from "../Logo"
import Section from "../Section"

import logoMenlo from "../../images/logo-menlo.png"
import logoMenloAlt from "../../images/logo-menlo-alt.png"

const Container = styled.div<{ filled: boolean }>`
  display: none;
  background-color: ${({ filled, theme }) =>
    filled ? theme.palette.white : "transparent"};
  background-image: linear-gradient(
    ${({ filled, theme }) =>
      filled ? "" : `${theme.palette.black}70, transparent`}
  );
  box-shadow: ${({ filled, theme }) =>
    filled ? `-4px 4px 10px ${theme.palette.mineShaft}10` : "none"};
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;

  .content-container {
    height: ${({ filled }) => (filled ? "70px" : "90px")};
    transition: height 0.5s;
    display: flex;
    justify-content: space-between;

    div.left-side {
      height: 100%;
      display: flex;
      gap: 40px;
      align-items: center;
      white-space: nowrap;

      .links-container {
        display: flex;
        gap: 40px;
      }
    }

    div.right-side {
      height: 100%;
      display: flex;
      align-items: center;
      gap: 10px;

      p {
        font-size: 14px;
        color: ${({ filled, theme }) =>
          filled ? theme.palette.mineShaft : theme.palette.white};
        margin: 0;
      }

      img {
        height: 36px;
        margin: 0;
        transition: height 0.2s;

        :hover {
          height: 40px;
        }
      }
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: block;
  }
`

const DesktopLink = styled(Link)<{ isfilled: number }>`
  color: ${({ isfilled, theme }) =>
    isfilled ? theme.palette.black : theme.palette.white};
  text-decoration: none;
  font-size: 14px;
  opacity: 0.6;
  font-family: "Poppins", sans-serif;
  transition: opacity 0.2s;

  &:hover {
    opacity: 1;
  }

  &.active {
    opacity: 1;
    font-family: "PoppinsSemiBold", sans-serif;
    cursor: default;
  }
`

type Props = {
  filled?: boolean
}

const NavDesktop = ({ filled = false }: Props): JSX.Element => {
  const [activeLink, setActiveLink] = useState("/")
  const contentColor = filled ? "black" : "white"
  const links = [
    {
      to: "/",
      text: "Welcome",
    },
    {
      to: "/services",
      text: "Our services",
    },
    {
      to: "/contact",
      text: "Contact us",
    },
  ]

  useEffect(() => {
    if (typeof window !== "undefined") {
      setActiveLink(location.pathname)
    }
  })

  return (
    <>
      <Container filled={filled}>
        <Section>
          <div className="content-container">
            <div className="left-side">
              <Logo height={40} color={contentColor} />
              <div className="links-container">
                {links.map((link, index) => (
                  <DesktopLink
                    to={link.to}
                    key={index + link.text}
                    className={activeLink == link.to ? "active" : ""}
                    isfilled={filled ? 1 : 0}
                  >
                    {link.text}
                  </DesktopLink>
                ))}
              </div>
            </div>
            <div className="right-side">
              <p>In association with</p>
              <a href="https://menlopark.co.za/" target="_blank">
                <img
                  src={filled ? logoMenlo : logoMenloAlt}
                  alt="Menlopark logo"
                />
              </a>
            </div>
          </div>
        </Section>
      </Container>
    </>
  )
}

export default NavDesktop
