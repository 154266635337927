/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useCallback, useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"

import { ThemeProvider } from "styled-components"
import { theme } from "../theme"
import "../css/layout.css"

import Footer from "./Footer"
import NavDesktop from "./navigation/NavDesktop"
import NavMobile from "./navigation/NavMobile"

const Layout = ({ children }) => {
  const [filled, setFilled] = useState(false)

  const changeNavbarColor = useCallback(() => {
    if (window.scrollY >= 50) {
      setFilled(true)
    } else {
      setFilled(false)
    }
  }, [])

  useEffect(() => {
    changeNavbarColor()
    window.addEventListener("scroll", changeNavbarColor)
  }, [changeNavbarColor])

  return (
    <ThemeProvider theme={theme}>
      <NavDesktop filled={filled} />
      <NavMobile filled={filled} />
      <div style={{ overflow: "hidden" }}>
        <main>{children}</main>
      </div>
      <Footer />
    </ThemeProvider>
  )
}

export default Layout
