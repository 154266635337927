export const contactInfo = {
  message: "",
  phone: "+27 066 304 0756",
  formattedPhone: "270663040756",
  email: "admin@playersswim.co.za",
  facebookUser: "playersswim",
  instagramUser: "playersswim17",
  addressLine1: "Rosemary Rd, Menlo Park,",
  addressLine2: "Pretoria, Gauteng, South Africa",
}

export const contactLinks = {
  email: `mailto:${contactInfo.email}`,
  phone: `tel:+${contactInfo.formattedPhone}`,
  map: "https://www.google.com/maps/place/25%C2%B045'56.9%22S+28%C2%B015'33.3%22E/@-25.765794,28.259235,1182m/data=!3m2!1e3!4b1!4m5!3m4!1s0x0:0x582a7610b53af1be!8m2!3d-25.765794!4d28.259235",
  instagram: `https://www.instagram.com/${contactInfo.instagramUser}/`,
  facebook: `https://www.facebook.com/${contactInfo.facebookUser}/`,
  whatsapp: `https://api.whatsapp.com/send/?phone=${contactInfo.formattedPhone}&text=${contactInfo.message}&app_absent=0`
}