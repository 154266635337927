import React, { CSSProperties } from "react"
import styled from "styled-components"

const Container = styled.svg<{
  scale: string
}>`
  display: block;
  margin-bottom: -1px;
  width: 100%;
  height: auto;
  transform: ${({ scale }) => scale};
`

type Props = {
  height?: number
  color: "#222222" | "#f2f2f2" | "#ffffff"
  flipHorizontal?: boolean
  flipVertical?: boolean
  position?: string
  style?: CSSProperties
}

const Slice = ({
  height = 100,
  color = "#ffffff",
  flipHorizontal,
  flipVertical,
  style,
}: Props): JSX.Element => {
  const scale =
    flipHorizontal && flipVertical
      ? "scaleX(-1) scaleY(-1)"
      : flipHorizontal
      ? "scaleX(-1)"
      : flipVertical
      ? "scaleY(-1)"
      : "scaleX(1) scaleY(1)"

  return (
    <Container
      height={height}
      width="100%"
      viewBox={`0 0 1450 ${height}`}
      strokeWidth="1"
      preserveAspectRatio="none"
      stroke={color}
      scale={scale}
      style={style}
    >
      <path d={`M0 0 V${height} H1450 Z`} fill={color} />
    </Container>
  )
}

export default Slice
