import { DefaultTheme } from "styled-components"

export const theme: DefaultTheme = {
  palette: {
    black: "#000000",
    white: "#ffffff",
    mineShaft: "#222222",
    concrete: "#f2f2f2",
    scienceBlue: "#0154d1",
    supernova: "#ffca0f",
    deepSeaGreen: "#075e54",
  }, 
  breakpoints: {
    sm: "768px",
    md: "992px",
    lg: "1200px",
    xl: "1440px",
  },
}