import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import styled from "styled-components"

import iconMail from "../images/social/icon-mail.svg"
import iconInstagram from "../images/social/icon-instagram.svg"
import iconFacebook from "../images/social/icon-facebook.svg"
import iconWhatsapp from "../images/social/icon-whatsapp.svg"
import { contactInfo, contactLinks } from "../utils/constants"

import Logo from "./Logo"
import Section from "./Section"
import { AnchorLink } from "gatsby-plugin-anchor-links"

const Container = styled.footer`
  background-color: ${({ theme }) => theme.palette.mineShaft};
  color: #fff;

  .logo-container {
    margin-bottom: 20px;
  }

  .logo {
    filter: invert(100%);
  }

  p {
    margin: 0;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;

  .social-icons {
    display: flex;
    justify-content: left;
    gap: 10px;
  }

  div#philosophy {
    max-width: 550px;

    p {
      margin-bottom: 10px;
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 50px;
    justify-content: space-between;

    .social-icons {
      justify-content: right;
    }
  }
`

const LinkSection = styled.div`
  font-size: 12px;
  display: flex;
  flex-wrap: wrap;
  row-gap: 50px;
  column-gap: 100px;

  .link-group {
    display: flex;
    flex-direction: column;
    row-gap: 5px;

    h1 {
      font-size: 16px;
      text-transform: uppercase;
      margin: 0;
    }

    p {
      color: #fff;
      opacity: 0.7;
      margin: 0;
    }
  }

  .footer-link,
  a {
    color: #fff;
    opacity: 0.7;
    text-decoration: none;
    transition: opacity 0.2s;
    margin: 0;

    :hover {
      opacity: 1;
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-direction: row;
    column-gap: 100px;
    flex-wrap: nowrap;
  }
`

const Footer = () => {
  return (
    <Container>
      <Section>
        <div className="logo-container">
          <Logo color="white" />
        </div>
        <Content>
          <div id="philosophy" className="grid-item">
            <p>
              We pride ourselves on using innovative, efficient swimming
              techniques to help our swimmers evolve in a way that works for
              them. Players maintains a supportive culture, both inside and
              outside of the pool.
            </p>
            <p>
              We believe in activating the mental, spritual and physical aspects
              of swimming to allow our swimmers to create successful
              environments in their swimming careers.
            </p>
          </div>
          <LinkSection className="grid-item">
            <div className="link-group">
              <h1>Home</h1>
              <AnchorLink className="footer-link" to="/#welcome">
                Welcome
              </AnchorLink>
              <AnchorLink className="footer-link" to="/#facilities">
                Facilities
              </AnchorLink>
              <AnchorLink className="footer-link" to="/#location">
                Location
              </AnchorLink>
              <AnchorLink className="footer-link" to="/contact">
                Contact
              </AnchorLink>
            </div>
            <div className="link-group">
              <h1>Services</h1>
              <AnchorLink className="footer-link" to="/services#learn-to-swim">
                Learn to swim
              </AnchorLink>
              <AnchorLink className="footer-link" to="/services#group-1">
                Competitive
              </AnchorLink>
              <AnchorLink className="footer-link" to="/services#ladies">
                Ladies
              </AnchorLink>
              <AnchorLink className="footer-link" to="/services#masters">
                Masters
              </AnchorLink>
            </div>
            <div className="link-group">
              <h1>Reach us</h1>
              <a href={contactLinks.email}>{contactInfo.email}</a>
              <a href={contactLinks.whatsapp} target="_blank">
                {contactInfo.phone}
              </a>
              <a href={contactLinks.map} target="_blank">
                {contactInfo.addressLine1}
                <br />
                {contactInfo.addressLine2}
              </a>
            </div>
          </LinkSection>
          <p className="grid-item">
            © Players Swim Pty Ltd, {new Date().getFullYear()}
          </p>
          <div className="grid-item social-icons">
            <a href={contactLinks.email} target="_blank">
              <img src={iconMail} width={32} height={32} alt="Email" />
            </a>
            <a href={contactLinks.instagram} target="_blank">
              <img src={iconInstagram} width={32} height={32} alt="Instagram" />
            </a>
            <a href={contactLinks.facebook} target="_blank">
              <img src={iconFacebook} width={32} height={32} alt="Facebook" />
            </a>
            <a href={contactLinks.whatsapp} target="_blank">
              <img src={iconWhatsapp} width={32} height={32} alt="Whatsapp" />
            </a>
          </div>
        </Content>
      </Section>
    </Container>
  )
}

export default Footer
