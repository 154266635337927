import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import Logo from "../Logo"
import Section from "../Section"
import IconMobileMenu from "../icons/IconMobileMenu"

import iconClose from "../../images/icon-close.svg"

const Container = styled.div<{ filled: boolean }>`
  display: block;
  background-color: ${({ filled, theme }) =>
    filled ? theme.palette.white : "transparent"};
  background-image: linear-gradient(
    ${({ filled, theme }) =>
      filled ? "" : `${theme.palette.black}70, transparent`}
  );
  box-shadow: ${({ filled, theme }) =>
    filled ? `-4px 4px 10px ${theme.palette.mineShaft}10` : "none"};
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;

  .content-container {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: none;
  }
`

const MobileLink = styled(Link)`
  color: ${({ theme }) => theme.palette.black};
  text-decoration: none;
  font-size: 18px;
  opacity: 0.6;
  font-family: "PoppinsMedium", sans-serif;
  transition: opacity 0.2s;

  &:hover {
    opacity: 1;
  }

  &.active {
    opacity: 1;
    font-family: "PoppinsSemiBold", sans-serif;
    cursor: default;
  }
`

const MobileLinksContainer = styled.div<{ shouldShow: boolean }>`
  background-color: ${({ theme }) => theme.palette.white};
  box-shadow: ${({ theme }) => `0px 0px 10px ${theme.palette.mineShaft}10`};
  //   position: absolute;
  padding: 20px 50px;
  //   overflow: hidden;
  //   top: 0px;
  //   left: 0px;
  //   width: 100vw;
  //   height: 100vh;
  z-index: 99999;
  height: 100vh;
  left: 0vw;
  position: fixed;
  overflow: hidden;
  scrollbar-width: 0px;
  top: 0px;
  width: 100%;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      margin: 0;
    }
  }

  .links-container {
    display: flex;
    flex-direction: column;
    color: ${({ theme }) => theme.palette.mineShaft};
    gap: 20px;
    margin-top: 50px;

    hr {
      background-color: ${({ theme }) => `${theme.palette.black}20`};
      margin: 0;
    }
  }
`

type Props = {
  filled?: boolean
}

const NavMobile = ({ filled = false }: Props): JSX.Element => {
  const [activeLink, setActiveLink] = useState("/")
  const [shouldShow, setShouldShow] = useState(false)

  const contentColor = filled ? "black" : "white"
  const links = [
    {
      to: "/",
      text: "Welcome",
    },
    {
      to: "/services",
      text: "Our services",
    },
    {
      to: "/contact",
      text: "Contact us",
    },
  ]

  useEffect(() => {
    if (typeof window !== "undefined") {
      setActiveLink(location.pathname)
    }
  })

  return (
    <>
      <Container filled={filled}>
        <Section>
          <div className="content-container">
            <Logo height={40} color={contentColor} />
            <IconMobileMenu
              color={contentColor}
              onClick={() => setShouldShow(true)}
            />
          </div>
        </Section>
      </Container>
      {shouldShow ? (
        <MobileLinksContainer shouldShow={shouldShow}>
          <div className="header">
            <Logo height={40} />
            <img src={iconClose} onClick={() => setShouldShow(false)} />
          </div>
          <div className="links-container">
            {links.map((link, index) => (
              <>
                <MobileLink
                  to={link.to}
                  key={index + link.text}
                  className={activeLink == link.to ? "active" : ""}
                >
                  {link.text}
                </MobileLink>
                <hr />
              </>
            ))}
          </div>
        </MobileLinksContainer>
      ) : null}
    </>
  )
}

export default NavMobile
